import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { User } from 'src/app/models/User';
import { StatusConfig } from 'src/app/models/StatusConfig';

export interface RequestOptions {
    headers?: HttpHeaders | Record<string, string | string[]>;
    context?: HttpContext;
    params?: HttpParams | Record<string, string | number | boolean | readonly (string | number | boolean)[]>;
    reportProgress?: boolean;
}

interface HttpRequestOptions extends RequestOptions {
    body?: any;
    context?: HttpContext;
    observe?: 'body' | 'events' | 'response';
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean
}
@Injectable({
    providedIn: 'root'
})
export class ApiService {

    currentUser: User;

    constructor(private http: HttpClient , private authenticationService: AuthenticationService ) {
        this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        });
    }

    getApiUrl(relativeUrl: string): string {
        const baseUrl = environment.apiBaseUrl;
        const url = `${baseUrl}/${relativeUrl}`;
        return url;
    }

    private request(method: string, relativeUrl: string, options: HttpRequestOptions) {
        const $ = this.http.request(method, this.getApiUrl(relativeUrl), options);

        if(this.currentUser != null && this.currentUser.expiresAt != null){
            const now = new Date();
            const expiresAt = new Date(this.currentUser.expiresAt);

            //  // Convertir la diferencia de tiempo de milisegundos a minutos
            const diffInMinutes = (expiresAt.getTime() - now.getTime()) / (1000 * 60);

            // 10 min antes de que caduque volvemos a pedir el token
            if (diffInMinutes <= 10 && this.authenticationService.getPswd() != null) {
                this.refreshToken(this.currentUser.loginName, this.authenticationService.getPswd()).catch(error => {console.error(error);});
            }
        }
        return lastValueFrom($);
    }

    private async refreshToken(loginName : string,  password : string) {
        const body = { loginName: loginName, password: password };
        const $ = this.http.request('POST', this.getApiUrl("users/authenticate"),  {body} ) ;
        const user =( await lastValueFrom($)) as User ;
        sessionStorage.setItem('currentUser', JSON.stringify(user));

        this.authenticationService.currentUserSubject.next(user);
    }

    private requestJson<T>(method: string, relativeUrl: string, body?: any, options?: RequestOptions): Promise<T> {
        const op = Object.assign({observe: 'body', responseType: 'json', body}, options) as HttpRequestOptions;
        return this.request(method, relativeUrl, op);
    }

    private requestBlob(method: string, relativeUrl: string, body?: any, options?: RequestOptions): Promise<Blob> {
        const op = Object.assign({observe: 'body', responseType: 'blob', body}, options) as HttpRequestOptions;
        return this.request(method, relativeUrl, op);
    }

    get<T>(relativeUrl: string, options?: RequestOptions): Promise<T> {
        return this.requestJson('GET', relativeUrl, undefined, options);
    }

    post<T>(relativeUrl: string, body?: any, options?: RequestOptions): Promise<T> {
        return this.requestJson('POST', relativeUrl, body, options);
    }

    delete<T>(relativeUrl: string, options?: RequestOptions): Promise<T> {
        return this.requestJson('DELETE', relativeUrl, undefined, options);
    }

    getBlob(relativeUrl: string, options?: RequestOptions): Promise<Blob> {
        return this.requestBlob('GET', relativeUrl, undefined, options);
    }

    postBlob(relativeUrl: string, body: any, options?: RequestOptions): Promise<Blob> {
        return this.requestBlob('POST', relativeUrl, body, options);
    }

    loadStatusConfig(): Observable<StatusConfig> {
        return this.http.get<StatusConfig>('assets/status.config.json').pipe(
            map((config) => {
                return config;
            }),
            catchError((error) => {
                console.error('Could not load the status config', error);
                return throwError(error);
            })
        );
    }
}
