import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private messageService: MessageService, private translateService: TranslateService) { }

    showSuccess(summary: string, detail: string, translate: boolean, life?: number) {
        if (translate)
            this.translate('success', summary, detail, life);
        else
            this.messageService.add({ severity: 'success', summary: summary, detail: detail, life: life});
    }

    showInfo(summary: string, detail: string, translate: boolean) {
        if (translate)
            this.translate('info', summary, detail);
        else
            this.messageService.add({ severity: 'info', summary: summary, detail: detail });
    }

    showWarn(summary: string, detail: string, translate: boolean) {
        if (translate)
            this.translate('warn', summary, detail);
        else
            this.messageService.add({ severity: 'warn', summary: summary, detail: detail });
    }

    showError(summary: string, detail: string, translate: boolean, life?: number, key?: string, data?: any) {
        if (translate)
            this.translate('error', summary, detail, life, key, data);
        else
            this.messageService.add({ severity: 'error', summary: summary, detail: detail, life: life, key: key, data: data});
    }

    private translate(severity: string, summary: string, detail: string, life?: number, key?: string, data?: any) {
        this.translateService.get(summary).subscribe((translatedSummary: string) => {
            this.translateService.get(detail).subscribe((translatedDetail: string) => {
                this.messageService.add({ severity: severity, summary: translatedSummary, detail: translatedDetail, life: life, key: key, data: data});
            });
        });
    }
}
