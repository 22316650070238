<div *ngIf="requestDetailService.request">
    <div class="detail-header">
        <div>
            <div class="flex">
                <button
                    pButton
                    (click)="navigateToMyRequests()"
                    [rounded]="true"
                    [text]="true"
                    class="P8"
                    styleClass="p-button-rounded p-button-danger p-button-text"
                >
                    <span class="material-symbols-outlined">arrow_back</span>
                </button>
                <h3 pTooltip="{{'REQUEST_DETAIL.NAME' | translate }}" tooltipPosition="top">
                    {{ requestDetailService.getRequestFullPersonName() }}
                </h3>
                <span
                    *ngIf="requestDetailService.request.personQuery.birthDate"
                    class="bold400 font14"
                    pTooltip="{{ 'REQUEST_DETAIL.BIRTH_DATE' | translate }}"
                    tooltipPosition="top"
                >
                    ({{ requestDetailService.request.personQuery.birthDate | date:'dd/MM/yyyy' }})
                </span>
                <span class="mini-separator"></span>
                <span class="bold600 font14" pTooltip="{{ 'REQUEST_DETAIL.LABORAL_REGIME' | translate }}" tooltipPosition="top">
                    {{ requestService.getPersonTypeTitle(requestDetailService.request.personQuery.personType) | translate}}
                </span>
                <p class="font11" pTooltip="{{ 'REQUEST_DETAIL.QUERY_ID' | translate }}" tooltipPosition="top">
                    {{ requestDetailService.request.personQuery.queryId }}
                </p>
            </div>

            <div class="flex PL50">
                <p pTooltip="{{ 'REQUEST_DETAIL.QUERY_TYPE' | translate }}" tooltipPosition="top">
                    <span class="material-symbols-outlined">contract</span>
                    {{ requestService.getQueryTypeTitle(requestDetailService.request.personQuery.queryType) | translate }}
                </p>
                <p pTooltip="{{ 'REQUEST_DETAIL.EMAIL' | translate }}" tooltipPosition="top">
                    <span class="material-symbols-outlined">mail</span>
                    {{ requestDetailService.request.personQuery.email }}
                </p>
                <p pTooltip="{{ 'REQUEST_DETAIL.PHONE' | translate }}" tooltipPosition="top">
                    <span class="material-symbols-outlined">phone</span>
                    {{ requestDetailService.request.personQuery.phoneNumber }}
                </p>
                <p pTooltip="{{ 'REQUEST_DETAIL.ID_NUMBER' | translate }}" tooltipPosition="top">
                    <span class="material-symbols-outlined">id_card</span>
                    {{ requestDetailService.request.personQuery.personId }}
                </p>
            </div>
        </div>

        <div class="flex">
            <div class="flex-column" *ngIf="requestDetailService.request.personQuery.personIdExpirationDate">
                <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.ID_EXPIRATION_DATE' | translate }}</p>
                <p class="date-box">{{ requestDetailService.request.personQuery.personIdExpirationDate | date:'dd/MM/yyyy' }}</p>
            </div>
            <div class="flex-column">
                <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.REQUEST_DATE' | translate }}</p>
                <p class="date-box">{{requestDetailService.request.personQuery.createdDateTime | date:'dd/MM/yyyy HH:mm' }}</p>
            </div>
            <div class="flex-column" *ngIf="requestDetailService.request.personQuery.isDocumentsDowloadCompleted">
                <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.DOWNLOAD_DOCUMENTS' | translate }}</p>
                <p class="date-box document-check">
                    <span class="material-symbols-outlined">done_all</span>
                </p>
            </div>
            <div class="flex-column" *ngIf="requestDetailService.feasibilityData.isCreditOperationMade">
                @if(requestDetailService.feasibilityData.isCreditGranted){
                    <p class=" font11 MB0 center">{{ 'REQUEST_DETAIL.FEASIBLE' | translate }}</p>
                    <p class="date-box document-check">
                        <span class="material-symbols-outlined">check</span>
                    </p>
                }@else {
                    <p class="font11 MB0 center">{{ 'REQUEST_DETAIL.NOT_FEASIBLE' | translate }}</p>
                    <p class="date-box document-denied">
                        <span class="material-symbols-outlined">close</span>
                    </p>
                }
            </div>
        </div>

        <div class="flex-column align-center">
            <p
                class="actual-status {{ requestDetailService.request.personQuery.status }}"
                pTooltip="{{ 'SUBSTATUS.' + requestDetailService.request.personQuery.substatus | translate }}"
                tooltipPosition="left"
            >
                @if(isTemporaryFinishedStatus()) {
                    {{ 'STATUS.FINISHED' | translate }}
                } @else {
                    {{ 'STATUS.' + requestDetailService.request.personQuery.status | translate }}
                }
            </p>
        </div>
    </div>
</div>
