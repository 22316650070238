<div class="active-filters">
    <ng-container *ngFor="let key of formKeys">
        <ng-container [ngSwitch]="key">
            <ng-container *ngIf="queryId">
                <div class="applied-filter" *ngSwitchCase="'queryId'">
                    <span class="filter-name">{{ "FILTER.QUERY_ID" | translate }}</span>
                    <span class="filter-value">{{ queryId }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'queryType'">
                    <span class="filter-name">{{ "FILTER.REQUEST_TYPE" | translate }}</span>
                    <span class="filter-value">{{ requestService.getQueryTypeTitle(getFormControlObject(key).value) | translate }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="personId">
                <div class="applied-filter" *ngSwitchCase="'personId'">
                    <span class="filter-name">{{ "FILTER.NAME_ID" | translate }}</span>
                    <span class="filter-value">{{ personId }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="firstName">
                <div class="applied-filter" *ngSwitchCase="'firstName'">
                    <span class="filter-name">{{ "FILTER.PERSON_ENTITY" | translate }}</span>
                    <span class="filter-value">{{ firstName }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="email">
                <div class="applied-filter" *ngSwitchCase="'email'">
                    <span class="filter-name">{{ "FILTER.EMAIL" | translate }}</span>
                    <span class="filter-value">{{ email }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="phoneNumber">
                <div class="applied-filter" *ngSwitchCase="'phoneNumber'">
                    <span class="filter-name">{{ "FILTER.PHONE" | translate }}</span>
                    <span class="filter-value">{{ phoneNumber }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'personType'">
                    <span class="filter-name">{{ "MY_REQUESTS.LABORAL_REGIME" | translate }}</span>
                    <span class="filter-value">{{ requestService.getPersonTypeTitle(getFormControlObject(key).value)| translate }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'startDate'">
                    <span class="filter-name">{{'FILTER.FROM' | translate }}</span>
                    <span class="filter-value">{{ getFormControlObject(key).value | date: 'dd/MM/yyy' }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'endDate'">
                    <span class="filter-name">{{'FILTER.TO' | translate }}</span>
                    <span class="filter-value">{{ getFormControlObject(key).value | date: 'dd/MM/yyy' }}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="getFormControlObject(key).value">
                <div class="applied-filter" *ngSwitchCase="'status'">
                    <span class="filter-name">{{ "MY_REQUESTS.STATUS" | translate }}</span>
                    <span class="filter-value">{{ requestService.getRequestStatusTitle(getFormControlObject(key).value) | translate}}</span>
                    <button class="clear-filter" (click)="clearFilter(key)">
                        <span class="material-symbols-outlined">cancel</span>
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="requestsFilterService.query !== '' && requestsFilterService.query !== null">
        <div class="applied-filter">
            <span class="filter-name">{{ "FILTER.STRING_QUERY" | translate }}</span>
            <span class="filter-value">{{ requestsFilterService.query }}</span>
            <button class="clear-filter" (click)="requestsFilterService.clearGlobalSearch()">
                <span class="material-symbols-outlined">cancel</span>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="thereIsMoreThanOneFilterActive()">
        <button class="btn-clean-filters" (click)="clearAllFiltersAndGlobalSearch()">
            <span class="material-symbols-outlined">filter_alt_off</span>{{ "FILTER.CLEAN_ALL" | translate }}
        </button>
    </ng-container>
</div>
