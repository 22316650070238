import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
    selector: 'app-json-viewer',
    templateUrl: './json-viewer.component.html'
})
export class JsonViewerComponent implements OnInit {

    objectToView: object;

    constructor(private toast: ToastService) { }

    ngOnInit(): void {
        const savedObject = localStorage.getItem("jsonViewerObject");
        if (savedObject == null) {
            this.toast.showError('JSONVIEWER.SAVED_OBJECT_ERROR.TITLE', 'JSONVIEWER.SAVED_OBJECT_ERROR.MESSAGE', true);
        }
        else
            this.objectToView = JSON.parse(savedObject);
    }
}
