import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { HomeComponent } from './main/home/home.component';
import { JsonViewerComponent } from './utils/json-viewer/json-viewer.component';
import { LoginComponent } from './main/login/login.component';
import { AdministrationComponent } from './main/home/administration/administration.component';
import { MyRequestsComponent } from './main/home/my-requests/my-requests.component';
import { RequestDetailComponent } from './main/home/request-detail/request-detail.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },

    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent, canActivate: [authGuard], children:
        [
            { path: 'requests', component: MyRequestsComponent, canActivate: [authGuard] },
            { path: 'request/:id', component: RequestDetailComponent, canActivate: [authGuard] },
            { path: 'administration', component: AdministrationComponent, canActivate: [authGuard] }
        ]
    },

    { path: 'json-viewer', component: JsonViewerComponent, canActivate: [authGuard] },
    { path: '**', redirectTo: 'home/requests' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
