<p-sidebar
    [(visible)]="sidebarVisible"
    *ngIf="sidebarVisible"
    position="right"
    styleClass="W700"
    dismissible="false"
    closeOnEscape="false"
    (onHide)="newRequestService.resetSidebar()"
>
    <ng-template pTemplate="header">
        <div class="sidebar-header">
            <h6 class="sidebar-title">{{ "MY_REQUESTS.NEW" | translate }}
                <span class="new-request-specs" *ngIf="newRequestService.getRequestTranslatedInfo().length > 0">
                    {{ newRequestService.getRequestTranslatedInfo() | translate }}
                </span>
            </h6>
        </div>
    </ng-template>

    <ng-template pTemplate="content">
        <app-new-request />
    </ng-template>
</p-sidebar>

<div class="main-content">
    <!-- <app-filter (filterEventEmitter)="updateList($event)"></app-filter> -->
    <form [formGroup]="requestsFilterService.form">

    <!-- DISEÑO PROVISIONAL: esta tabla tenía un [tableStyle]="{ 'min-width': '1820px' }", lo he quitado temporalmente -->
        <p-table
            [loading]="requestsFilterService.isLoading"
            [showLoader]="false"
            [value]="filterResponse?.requestList"
            (onSort)="onSortChange($event)"
            [sortField]="requestsFilterService.sortField | async"
            [sortOrder]="requestsFilterService.sortOrder | async"
            [totalRecords]="filterResponse?.requestList.length"
            styleClass="p-datatable-gridlines"
            scrollHeight="calc(100vh - 282px)"
            #table
        >

            <ng-template pTemplate="caption">
                <!-- <div class="state-filters">
                <ng-container *ngFor="let status of requestService.statusConfig?.status">
                    <app-status-counter [status]="status.id"></app-status-counter>
                </ng-container>
                </div> -->
                <div class="table-header">
                    <h5 class="table-title">{{ "MY_REQUESTS.TITLE" | translate }}</h5>
                    <div class="table-filters">
                        <div class="form-group">
                            <h6
                                class="section-title"
                                showdelay="500"
                                pTooltip="{{'MY_REQUESTS.FILTER_TOOLTIP' | translate}}"
                                tooltipPosition="bottom"
                            >
                                {{ "MY_REQUESTS.FILTERS" | translate }}
                            </h6>
                            <app-requests-active-filters [table]="table" />
                        </div>
                    </div>

                    <div class="table-actions">
                        <div>
                            <p-iconField iconPosition="left" class="ml-auto outline-btn">
                                <p-inputIcon><i class="pi pi-search"></i></p-inputIcon>
                                <input pInputText type="text" (input)="globalSearch($event)" placeholder="{{'MY_REQUESTS.SEARCH' | translate}}" />
                            </p-iconField>
                        </div>

                        <button class="outline-btn excel-btn" (click)="exportToExcel()">
                            <span class="material-symbols-outlined ico-list">file_save</span>
                            {{ "MY_REQUESTS.EXCEL" | translate }}
                        </button>

                        <button class="outline-btn" (click)="sync()">
                            <span class="material-symbols-outlined ico-list">sync</span>
                            <div style="display: flex; flex-direction: column; padding-right: 10px;">
                                {{ "MY_REQUESTS.SYNC" | translate }}
                                @if(requestsFilterService.lastSearchTime){
                                    <span style="font-size: xx-small;">
                                        {{ "MY_REQUESTS.LAST_TIME_SYNC" | translate}} {{requestsFilterService.lastSearchTime | date:"HH:mm"}}
                                    </span>
                                }
                            </div>
                        </button>

                        <button class="filled-btn" (click)="newRequest()">
                            <span class="material-symbols-outlined ico-list">add_circle</span>
                            {{ "MY_REQUESTS.NEW" | translate }}
                        </button>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="queryId" class="W280">
                        <div>
                            {{ "MY_REQUESTS.QUERY_ID" | translate }}
                            <p-sortIcon field="queryId" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('queryId')">
                                <ng-template pTemplate="filter">
                                    <div class="table-filter-input">
                                        <input
                                            class=""
                                            type="text"
                                            pInputText
                                            id="queryId"
                                            placeholder="{{ 'FILTER.PLACEHOLDERS.QUERY_ID' | translate}}"
                                            formControlName="queryId"
                                        >
                                        <span *ngIf="requestsFilterService.isLoading === true" class="p-dropdown-trigger-icon pi pi-spin pi-spinner"></span>
                                    </div>
                                </ng-template>
                            </p-columnFilter>
                        </div>

                    <th pSortableColumn="queryType" class="W160">
                        <div>
                            {{ "FILTER.REQUEST_TYPE" | translate }}
                            <p-sortIcon field="queryType" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('queryType')">
                                <ng-template pTemplate="filter">
                                    <div>
                                        <p-dropdown
                                            id="request-status"
                                            [options]="requestService.queryTypes"
                                            placeholder="{{ 'FILTER.SELECT' | translate }}"
                                            formControlName="queryType"
                                            optionLabel="title"
                                            optionValue="id"
                                        >
                                            <ng-template let-queryType pTemplate="item">
                                                <div>
                                                    <span>{{ queryType.title | translate }}</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="selectedItem">
                                                <div class="table-filter-input">
                                                    <span
                                                        *ngIf="requestsFilterService.isLoading === true"
                                                        class="p-dropdown-trigger-icon pi pi-spin pi-spinner">
                                                    </span>
                                                    <span>
                                                        {{ requestService.getQueryTypeTitle(requestsFilterService.form.get('queryType').value) | translate }}
                                                    </span>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>

                    <th pSortableColumn="personId" class="W120">
                        <div>
                            {{ "MY_REQUESTS.ID" | translate }}
                            <p-sortIcon field="personId" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('personId')">
                                <ng-template pTemplate="filter">
                                    <div class="table-filter-input">
                                        <span
                                            *ngIf="requestsFilterService.isLoading === true"
                                            class="p-dropdown-trigger-icon pi pi-spin pi-spinner">
                                        </span>
                                        <input
                                            type="text"
                                            pInputText
                                            id="_personId"
                                            placeholder="{{ 'FILTER.PLACEHOLDERS.ID' | translate}}"
                                            formControlName="personId"
                                        >
                                    </div>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>

                    <th pSortableColumn="firstName" class="W200">
                        <div>
                        {{ "FILTER.PERSON_ENTITY" | translate }}
                        <p-sortIcon field="firstName" />
                        <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('firstName')">
                            <ng-template pTemplate="filter">
                                <div>
                                    <div class="table-filter-input">
                                        <span
                                            *ngIf="requestsFilterService.isLoading === true"
                                            class="p-dropdown-trigger-icon pi pi-spin pi-spinner">
                                        </span>
                                        <input
                                            type="text"
                                            pInputText
                                            id="_firstName"
                                            placeholder="{{ 'FILTER.PLACEHOLDERS.NAME' | translate}}"
                                            formControlName="firstName"
                                        >
                                    </div>
                                </div>
                            </ng-template>
                        </p-columnFilter>
                        </div>
                    </th>

                    <th pSortableColumn="email" class="W200">
                        <div>
                            {{ 'FILTER.EMAIL' | translate }}
                            <p-sortIcon field="email" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('email')">
                                <ng-template pTemplate="filter">
                                    <div class="table-filter-input">
                                        <span
                                            *ngIf="requestsFilterService.isLoading === true"
                                            class="p-dropdown-trigger-icon pi pi-spin pi-spinner">
                                        </span>
                                        <input
                                            type="text"
                                            pInputText
                                            id="_email"
                                            placeholder="{{ 'FILTER.PLACEHOLDERS.EMAIL' | translate}}"
                                            formControlName="email"
                                        >
                                    </div>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>

                    <th pSortableColumn="phoneNumber" class="W100">
                        <div>
                            {{ "FILTER.PHONE" | translate}}
                            <p-sortIcon field="phoneNumber" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('phoneNumber')">
                                <ng-template pTemplate="filter">
                                    <div class="table-filter-input">
                                        <span
                                            *ngIf="requestsFilterService.isLoading === true"
                                            class="p-dropdown-trigger-icon pi pi-spin pi-spinner">
                                        </span>
                                        <input
                                            type="text"
                                            pInputText
                                            id="_phoneNumber"
                                            placeholder="{{ 'FILTER.PLACEHOLDERS.PHONE' | translate}}"
                                            formControlName="phoneNumber"
                                        >
                                    </div>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>

                    <!-- <th pSortableColumn="personType">
                        <div>
                            {{ "MY_REQUESTS.LABORAL_REGIME" | translate }}
                            <p-sortIcon field="personType" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('personType')">
                                <ng-template pTemplate="filter">
                                    <p-dropdown
                                        id="person-type"
                                        [options]="requestService.personTypes"
                                        placeholder="{{ 'FILTER.SELECT' | translate }}"
                                        formControlName="personType"
                                        optionLabel="title"
                                        optionValue="id"
                                    >
                                        <ng-template let-personType pTemplate="item">
                                            <div>
                                                <span>{{ personType.title | translate }}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="selectedItem">
                                            <div class="table-filter-input">
                                                <span
                                                    *ngIf="requestsFilterService.isLoading === true"
                                                    class="p-dropdown-trigger-icon pi pi-spin pi-spinner">
                                                </span>
                                                <span>
                                                    {{ requestService.getPersonTypeTitle(requestsFilterService.form.get('personType').value) | translate }}
                                                </span>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th> -->

                    <th pSortableColumn="createdDateTime" class="W130">
                        <div>
                            {{ "MY_REQUESTS.REQUEST_DATE" | translate }}
                            <p-sortIcon field="createdDateTime" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('startDate') || checkFilterFieldHasValue('endDate')">
                                <ng-template pTemplate="filter">
                                    <div class="MB10">
                                        <label class="font13 bold600" for="from">{{ "FILTER.FROM" | translate }}</label>
                                        <p-calendar
                                            id="from"
                                            formControlName="startDate"
                                            dateFormat="dd/mm/yy"
                                        />
                                    </div>
                                    <div>
                                        <label class="font13 bold600" for="to">{{ "FILTER.TO" | translate }}</label>
                                        <p-calendar
                                            id="to"
                                            formControlName="endDate"
                                            dateFormat="dd/mm/yy"
                                        />
                                    </div>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>

                    <th pSortableColumn="status" class="W140">
                        <div>
                            {{ "MY_REQUESTS.STATUS" | translate }}
                            <p-sortIcon field="status" />
                            <p-columnFilter [class.active-filter]="checkFilterFieldHasValue('status')">
                                <ng-template pTemplate="filter">
                                    <p-dropdown
                                        id="status"
                                        [options]="requestService.statusConfig.status"
                                        placeholder="{{ 'FILTER.SELECT' | translate }}"
                                        formControlName="status"
                                        optionLabel="title"
                                        optionValue="id"
                                    >
                                        <ng-template let-status pTemplate="item">
                                            <div>
                                                <span>{{ status.title | translate }}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="selectedItem">
                                            <div class="table-filter-input">
                                            <span
                                                *ngIf="requestsFilterService.isLoading === true"
                                                class="p-dropdown-trigger-icon pi pi-spin pi-spinner">
                                            </span>
                                            <span>
                                                {{ requestService.getRequestStatusTitle(requestsFilterService.form.get('status').value) | translate }}
                                            </span>
                                        </div>
                                        </ng-template>
                                    </p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>

                    <th class="W50" alignFrozen="right" pFrozenColumn [frozen]="true"></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-row>
                <tr>
                    <td>{{ row.queryId }}</td>
                    <td>{{ requestService.getQueryTypeTitle(row.queryType) | translate }}</td>
                    <td>{{ row.personId }}</td>
                    <td>{{ row.firstName }} {{ row.firstSurname }} {{ row.secondSurname }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.phoneNumber }}</td>
                    <!-- <td>{{ requestService.getPersonTypeTitle(row.personType) | translate }}</td> -->
                    <td>{{ row.createdDateTime | date:"dd/MM/yyyy HH:mm" }}</td>
                    <td class="status">
                        <p class="{{ row.status }}" pTooltip="{{ 'SUBSTATUS.' + row.substatus | translate }}" tooltipPosition="top">
                            {{ 'STATUS.' + row.status | translate }}
                        </p>
                    </td>
                    <td alignFrozen="right" pFrozenColumn [frozen]="true">
                        <button
                            type="button"
                            class="btn-table"
                            (click)="goToDetail(row.queryId)"
                            [pTooltip]="'MY_REQUESTS.DETAIL' | translate"
                            tooltipPosition="left"
                        >
                            <span class="material-symbols-outlined">move_item</span>
                        </button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
                <ng-container *ngIf="showIfTableIsLoadingAndEmpty()">
                    <tr *ngFor="let row of utilsService.range(15)">
                        <td *ngFor="let column of utilsService.range(9)">
                            <p-skeleton />
                        </td>
                    </tr>
                </ng-container>

                <ng-container *ngIf="requestsFilterService.isLoading === true && !showIfTableIsLoadingAndEmpty()">
                    <div class="table-loader-box">
                        <div>
                        <span class="p-dropdown-trigger-icon pi pi-spin pi-spinner"></span>
                        <p>{{ 'LOADING' | translate }}...</p>
                        </div>
                    </div>
                </ng-container>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="10" class="center bg-lightgray2  PT60 PB60">
                        <!-- <svg class="W200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2000 2000" enable-background="new 0 0 2000 2000" xml:space="preserve"> <g id="Layer_2"> </g> <g id="Layer_1"> <g> <path fill="#E6E6E6" d="M1574.4,602.3l-303.3,974.3l-963.9-171.7c0,0,266.4-263,445.9-981.6L1574.4,602.3z"/> <g> <path fill="#B3B3B3" d="M1187.7,1446.3l-970.5-112.2l852-116.8C1041.5,1287.5,1147.6,1434.9,1187.7,1446.3z"/> <path fill="#FFFFFF" d="M1293,1444.6c-1.2,0.4-2.4,0.7-3.6,1c-39.2,11-80.9,9-119.2-4.8c-67.8-24.3-166-75.8-101-223.6 l-852,116.8c3.6-1.4,412.5-162,650.4-812.9l915.2,259.5C1782.8,780.6,1596.3,1353.6,1293,1444.6z"/> </g> <polygon fill="#B3B3B3" points="867.6,521.1 1548.2,686.5 1540.3,711.9 		"/> <g> <line fill="none" stroke="#CCCCCC" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="932.6" y1="740.9" x2="1129.2" y2="802.5"/> <line fill="none" stroke="#CCCCCC" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="893.1" y1="821.1" x2="1245.7" y2="939.8"/> <line fill="none" stroke="#CCCCCC" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="863" y1="908.7" x2="1215.6" y2="1027.4"/> </g> </g> </g> </svg> -->
                        <svg
                            class="W200"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 2000 1500"
                            enable-background="new 0 0 2000 1500"
                            xml:space="preserve"
                        >
                            <g>
                                <path fill="#E6E6E6"
                                    d="M1728.3,246.2l-384.4,1235.2l-1222-217.7c0,0,337.7-333.4,565.3-1244.4L1728.3,246.2z"
                                />
                                <g>
                                    <path fill="#B3B3B3"
                                        d="M1238.1,1316.1L7.7,1173.9l1080.2-148.1C1052.7,1114.9,1187.2,1301.8,1238.1,1316.1z"
                                    />
                                    <path fill="#FFFFFF"
                                        d="M1371.5,1314c-1.5,0.4-3,0.9-4.5,1.3c-49.7,13.9-102.6,11.4-151.1-6c-86-30.9-210.4-96.1-128-283.5 L7.7,1173.9c4.6-1.8,522.9-205.4,824.5-1030.6l1160.2,329C1992.5,472.3,1756,1198.6,1371.5,1314z"
                                    />
                                </g>
                                <polygon fill="#B3B3B3" points="832.2,143.3 1695.1,352.9 1685,385.1" />
                                <g>
                                    <line fill="none" stroke="#CCCCCC" stroke-width="14" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-miterlimit="10" x1="914.6" y1="421.9" x2="1163.9" y2="500" />
                                    <line fill="none" stroke="#CCCCCC" stroke-width="14" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-miterlimit="10" x1="864.6" y1="523.6" x2="1311.5" y2="674" />
                                    <line fill="none" stroke="#CCCCCC" stroke-width="14" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-miterlimit="10" x1="826.5" y1="634.7" x2="1273.4" y2="785.1" />
                                </g>
                            </g>
                        </svg>
                        <p class="not-found-text" translate>{{ "MY_REQUESTS.NO_REQUESTS" }}</p>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </form>
</div>
