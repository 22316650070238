import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { provideUserIdleConfig } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { UtilsModule } from './utils/utils.module';
import { UsualModule } from './usual.module';
import { HomeModule } from './main/home/home.module';
import { HeaderModule } from './main/header/header.module';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import { DatePipe } from '@angular/common';
import { CustomizedMissingTranslationHandler } from './core/services/missing-translation-handler';
import { LoginModule } from './main/login/login.module';
import { AdministrationModule } from './main/home/administration/administration.module';
import { MyRequestsModule } from './main/home/my-requests/my-requests.module';
import { RequestDetailModule } from './main/home/request-detail/request-detail.module';
import { NewRequestModule } from './shared/components/new-request/new-request.module';
registerLocaleData(localeES);


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    // No se deben declarar componentes aqui.
    // Generar un modulo para vuestra funcionalidad/componentes.
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,

        // Modulos propios.
        UsualModule,
        UtilsModule,
        MyRequestsModule,
        NewRequestModule,
        RequestDetailModule,
        LoginModule,
        HomeModule,
        HeaderModule,
        AdministrationModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomizedMissingTranslationHandler },
            defaultLanguage: 'es'
        }),
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        MessageService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
        // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
        // and `ping` is 120 (2 minutes).
        provideUserIdleConfig({ idle: environment.idleSecondsTimeout, timeout: 1, ping: 120 }),
        ConfirmationService,
        { provide: LOCALE_ID, useValue: 'es-ES' }

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
