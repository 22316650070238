import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './login.service';
import { UserIdleService } from 'angular-user-idle';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    @Input() isDialog = false;

    loading = false;
    submitted = false;
    userFocused = false;
    passwordFocused = false;
    loginForm: FormGroup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private fb: FormBuilder,
        private userIdle: UserIdleService,
        private toastService: ToastService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/home/requests']).catch(error => {console.error(error);});
        }
        this.loginForm = this.fb.group({
            loginName: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    onSubmit() {
        if (this.loginForm.invalid) {
            this.toastService.showError('TOAST.ERROR', 'LOGIN.ALL_FIELDS_REQUIRED', true);
            return;
  	    }

  	    this.loading = true;

        this.loginService
            .login(this.loginForm.get('loginName').value, this.loginForm.get('password').value)
            .then(() => {
                this.resetFields();
                if (this.isDialog) {
                    this.loginService.showLoginDialog = false;
                    this.userIdle.startWatching();
                    return;
                }
                // get return url from route parameters or default to '/'
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home/requests';
                this.router.navigate([returnUrl]).catch(error => {console.error(error);});
            }).catch(error => {
                if (error.status === 400) { // the api returns 400 only when the credentials are invalid
                    this.toastService.showError('TOAST.ERROR', 'LOGIN.INVALID_CREDENTIALS', true);
                } else {
                    this.toastService.showError(`Error ${error.statusText}`, error.error.message, false);
                }
            }).finally(() => {
                this.loading = false;
  	        });
    }

    resetFields() {
  	    // this.loginForm.get('loginName').setValue('');
  	    // this.loginForm.get('password').setValue('');
  	    this.loginForm.reset(); // reset all the form fields
    }

}
