import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { UsualModule } from 'src/app/usual.module';
import { UtilsModule } from 'src/app/utils/utils.module';



@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        UsualModule,
        UtilsModule
    ],
    exports: [
        LoginComponent
    ]
})
export class LoginModule { }
