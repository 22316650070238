import { Injectable } from '@angular/core';
import { AppConfiguration, ClientEnviroment } from 'src/app/models/User';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService  {

    private configuration: AppConfiguration;

    constructor(private authenticationService: AuthenticationService) {
        //this.getConfiguration();
    }

    getConfiguration(): AppConfiguration {
        if (this.configuration == null) {
            this.configuration = this.authenticationService.currentUserValue.appConfiguration;
            this.setStyle();
        }
        return this.configuration;
    }

    setConfiguration(conf: AppConfiguration) {
        this.configuration = conf;
    }

    setStyle() {
        switch (this.configuration.clientEnviroment) {
            case ClientEnviroment.Santander:
                this.loadStyle('assets/css/client/santander.css');
                break;
            case ClientEnviroment.Sabadell:
                this.loadStyle('assets/css/client/sabadell.css');
                break;
            case ClientEnviroment.Unicaja:
                this.loadStyle('assets/css/client/unicaja.css');
                break;
            case ClientEnviroment.Experian:
                this.loadStyle('assets/css/client/experian.css');
                break;
        }
    }

    setDefaultStyle() {
        this.loadStyle('assets/css/client/experian.css');
    }

    loadStyle(styleName: string): void {
        const head = document.getElementsByTagName('head')[0];
        const existingLink = document.getElementById('app-theme') as HTMLLinkElement;

        if (existingLink) {
            existingLink.href = styleName;
        } else {
            const newLink = document.createElement('link');
            newLink.id = 'app-theme';
            newLink.rel = 'stylesheet';
            newLink.href = styleName;
            head.appendChild(newLink);
        }
    }
}
