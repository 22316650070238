import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestDetailService } from './request-detail.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { RequestDetailFeasibilityService } from './request-detail-feasibility/request-detail-feasibility.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { statesToHideCancelOTPButton, statesToHideContinueOTPButton, substatesToReopenBelender } from 'src/app/shared/constants/common.constants';
import { NewRequestService } from 'src/app/shared/components/new-request/new-request.service';


@Component({
    selector: 'app-request-detail',
    standalone: false,
    templateUrl: './request-detail.component.html'
})
export class RequestDetailComponent implements OnInit, OnDestroy {

    items = [];
    newRequestStatus: {
		title: string, step: number, requestLastStatus?: string
	} = { title: 'New request', step: 1 };

    belenderStatus: {
		actualStatus: string;
		actualStatusMessage: string;
	} = { actualStatus: '', actualStatusMessage: '' };
    consentBelenderUrl: SafeResourceUrl;

    constructor(
		public requestDetailService: RequestDetailService,
		private toastService: ToastService,
		private sanitizer: DomSanitizer,
		private translate: TranslateService,
		private confirmationService: ConfirmationService,
		private requestDetailFeasibilityService: RequestDetailFeasibilityService,
        public newRequestService: NewRequestService
    ) { }

    async ngOnInit() {
        await this.requestDetailService.getRequestFromUrl();
    }

    ngOnDestroy() {
        // reset the feasibility when the user navigates away from the request detail page
        this.requestDetailFeasibilityService.resetFeasibility();
        this.requestDetailService.resetFeasibilityData();

        this.requestDetailService.belenderConsentUrlBoxed = undefined;
    }

    reopenOTPWidget() {
        try {
            this.requestDetailService.isLoading = true;
            this.newRequestStatus = {
                title: "Continuar con el OTP",
                step: 3,
                requestLastStatus: this.requestDetailService.request.personQuery.substatus
            };
            this.belenderStatus = {
                actualStatus: this.requestDetailService.request.personQuery.substatus,
                actualStatusMessage: this.requestDetailService.request.personQuery.substatus
            };

            this.consentBelenderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.requestDetailService.belenderConsentUrlBoxed);
            this.newRequestService.isFinishingOTP = true;
        } catch (e) {
            console.log(e);
            this.toastService.showError(`Error ${e.status} (${e.statusText})`, `${e.error.detail}`, false);
        } finally {
            this.requestDetailService.isLoading = false;
        }
    }

    async cancelOTP() {
        try {
            this.requestDetailService.isLoading = true;

            const deleteResponse = await this.requestDetailService.cancelRequest({
                queryId: this.requestDetailService.queryId,
                requestReference: undefined,
                userName: undefined,
                userPassword: undefined
            });

            if (deleteResponse.status !== 'Canceled')
                this.toastService.showError('TOAST.ERROR', `${deleteResponse.status}`, false, 10000);
            else {
                this.toastService.showSuccess('TOAST.SUCCESS', 'NEW_REQUEST.CANCEL', true, 10000);
                await this.requestDetailService.getRequestFromUrl();
                //this.router.navigate['home/requests'];
            }
        } catch (e) {
            this.toastService.showError(`Error ${e.error.status} (${e.error.title})`, `${e.error.detail}`, false);
        } finally {
            this.requestDetailService.isLoading = false;
        }
    }

    confirmCancelOTP(event: Event) {
        if (!event || !event.target) return;

        this.confirmationService.confirm({
            target: event.target,
            message: this.translate.instant('REQUEST_DETAIL.CANCEL_REQUEST_CONFIRMATION'),
            acceptLabel: this.translate.instant('BUTTON.YES'),
            rejectLabel: this.translate.instant('BUTTON.NO'),
            accept: () => {
                this.cancelOTP().catch(error => {console.error(error);});
            }
        });
    }

    hideContinueOTPButton(): boolean {
        const request = this.requestDetailService.request;
        if (!request) return true;

        const status = request.personQuery.status;
        if (statesToHideContinueOTPButton.includes(status)) return true;

        const substatus = request.personQuery.substatus;
        return !substatesToReopenBelender.includes(substatus);
    }

    hideCancelOTPButton(): boolean {
        const request = this.requestDetailService.request;
        if (!request) return true;

        const status = request.personQuery.status;
        return statesToHideCancelOTPButton.includes(status);
    }
}
