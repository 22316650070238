<p-sidebar
    *ngIf="requestDetailService.isHistorySidebarVisible"
    [(visible)]="requestDetailService.isHistorySidebarVisible"
    position="right"
    styleClass="W700"
    dismissible="false"
    closeOnEscape="false"
>
    <ng-template pTemplate="header">
        <div class="sidebar-header">
            <h6 class="sidebar-title">{{ 'REQUEST_DETAIL.HISTORY' | translate }}</h6>
        </div>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="MT20">
            <table>
                <thead>
                    <th class="W160">{{ 'REQUEST_DETAIL.DATE_HOUR' | translate }}</th>
                    <th>{{ 'REQUEST_DETAIL.ACTION' | translate }}</th>
                </thead>
                <tbody>
                    <ng-container>
                        <tr>
                            <td>05/10/1983</td>
                            <td>Accion predeterminnada</td>
                        </tr>
                        <tr>
                            <td>05/10/1983</td>
                            <td>Accion predeterminnada</td>
                        </tr>
                        <tr>
                            <td>05/10/1983</td>
                            <td>Accion predeterminnada</td>
                        </tr>
                        <tr>
                            <td>05/10/1983</td>
                            <td>Accion predeterminnada</td>
                        </tr>
                        <tr>
                            <td>05/10/1983</td>
                            <td>Accion predeterminnada</td>
                        </tr>
                        <tr>
                            <td>05/10/1983</td>
                            <td>Accion predeterminnada</td>
                        </tr>
                        <tr>
                            <td>05/10/1983</td>
                            <td>Accion predeterminnada</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-template>
</p-sidebar>

<p-sidebar
    *ngIf="requestDetailService.isCalculateViabilitySidebarVisible"
    [(visible)]="requestDetailService.isCalculateViabilitySidebarVisible"
    position="right"
    styleClass="W1000"
    dismissible="false"
    closeOnEscape="false"
>

    <ng-template pTemplate="header">
        <div class="sidebar-header">
            <h6 class="sidebar-title">{{ 'REQUEST_DETAIL.CALCULATE_FEASIBILITY' | translate }}</h6>
        </div>
    </ng-template>

    <ng-template pTemplate="content">
        <app-request-detail-feasibility />
    </ng-template>
</p-sidebar>

<app-loading [isLoading]="requestDetailService.isLoading">
    <ng-container *ngIf="requestDetailService.request !== null">
        <p-sidebar
            [(visible)]="newRequestService.isFinishingOTP"
            *ngIf="newRequestService.isFinishingOTP"
            position="right"
            styleClass="W700"
            dismissible="false"
            closeOnEscape="false"
        >
            <ng-template pTemplate="header">
                <div class="sidebar-header">
                    <h6 class="sidebar-title">{{ "MY_REQUESTS.CONTINUE" | translate }}</h6>
                </div>
            </ng-template>

            <ng-template pTemplate="content">
                <app-new-request [newRequestStatus]="newRequestStatus" [trustedConsentUrlBoxed]="consentBelenderUrl" [belenderStatus]="belenderStatus" />
            </ng-template>
        </p-sidebar>

        <app-loading [isLoading]="requestDetailService.isLoading">
            <ng-container>
                <app-request-detail-header />
                <div class="detail-body-buttons">
                    <div class="request-detail-buttons" *ngIf="requestDetailService.request">
                        <button *ngIf="!hideCancelOTPButton()" class="outline-btn" (click)="confirmCancelOTP($event)">
                            <span class="material-symbols-outlined">block</span>
                            {{ "REQUEST_DETAIL.CANCEL" | translate }}
                        </button>

                        <button class="outline-btn" (click)="requestDetailService.refreshRequest()">
                            <span class=" material-symbols-outlined ico-list">sync</span>
                            <div class="PR10 flex-column" style="display: flex; flex-direction: column; padding-right: 10px;">
                                {{ "MY_REQUESTS.SYNC" | translate }}
                                <span class="font9">
                                    {{ "MY_REQUESTS.LAST_TIME_SYNC" | translate }} {{requestDetailService.lastQueryHour | date:"HH:mm" }}
                                </span>
                            </div>
                        </button>

                        <button *ngIf="!hideContinueOTPButton()" class="filled-btn" (click)="reopenOTPWidget()">
                            <span class="material-symbols-outlined">autoplay</span>
                            {{ "REQUEST_DETAIL.CONTINUE_OTP" | translate }}
                        </button>
                    </div>
                </div>
                <app-request-detail-documents *ngIf="requestDetailService.request" />
                <router-outlet />
            </ng-container>
        </app-loading>
    </ng-container>
</app-loading>
