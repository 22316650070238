import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NewRequestService {

    isFinishingOTP = false;
    myForm: FormGroup;
    selectedBelenderCircuit: number;

    requestorType: string;
    requestType: string;

    constructor(
        private fb: FormBuilder,
        private translateService: TranslateService
    ) {
        this.myForm = this.fb.group({});
    }

    resetRequestorAndRequestTypes() {
        this.requestorType = '';
        this.requestType = '';
    }

    pastDateValidator(field: string): ValidatorFn {
        return (): ValidationErrors | null => {
            const today = new Date();
            const inputDate = this.myForm?.get(field)?.value;

            if (inputDate <= today) {
                return { pastDateValidator: true }; //el dni está caducado
            }
            return null;
        };
    }

    futureDateValidator(field: string): ValidatorFn {
        return (): ValidationErrors | null => {
            const today = new Date();
            const inputDate = this.myForm?.get(field)?.value;

            if (inputDate >= today) {
                return { futureDateValidator: true };
            }
            return null;
        };
    }

    setForm(requestorType: string, requestType: string) {
        this.requestorType = requestorType;
        this.requestType = requestType;

        switch (requestorType) {
            case 'Physical_Person':
                switch (requestType) {
                    case 'Clave_Pin_SMS':
                    case 'Clave_Pin':
                        this.myForm = this.fb.group({
                            firstName: ['', [Validators.required, Validators.minLength(2)]],
                            firstSurname: ['', [Validators.required, Validators.minLength(2)]],
                            secondSurname: [''],
                            personId: ['', [Validators.required, this.nifNieValidator()]],
                            personIdExpirationDate: ['', [Validators.required, this.pastDateValidator('personIdExpirationDate')]],
                            birthDate: ['', [Validators.required, this.futureDateValidator('birthDate')]],
                            phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]{9}$')]],
                            email: ['', [Validators.required, Validators.email]],
                            personType: ['01', [Validators.required]],
                            documentationPack: ['', [Validators.required]]
                        });
                        this.selectedBelenderCircuit = 2;
                        break;
                    case 'SMS':
                        this.myForm = this.fb.group({
                            firstName: ['', [Validators.required, Validators.minLength(2)]],
                            firstSurname: ['', [Validators.required, Validators.minLength(2)]],
                            secondSurname: [''],
                            personId: ['', [Validators.required, this.nifNieValidator()]],
                            birthDate: ['', [Validators.required, this.futureDateValidator('birthDate')]],
                            phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]{9}$')]],
                            email: ['', [Validators.required, Validators.email]],
                            personType: ['', [Validators.required]],
                            documentationPack: ['', [Validators.required]]
                        });
                        this.selectedBelenderCircuit = 4;
                        break;
                    case 'Video_Id':
                        break;
                    case 'Know_Client':
                        break;
                }
                break;
            case 'Legal_Entity':
                break;
        }
    }

    getRequestTranslatedInfo() {
        let requestorTranslated = '';
        switch (this.requestorType) {
            case 'Physical_Person':
                requestorTranslated = 'NEW_REQUEST.PHYSICAL_PERSON';
                break;
            case 'Legal_Entity':
                requestorTranslated = 'NEW_REQUEST.LEGAL_ENTITY';
                break;
        }
        let requestTranslated = '';
        switch (this.requestType) {
            case 'Clave_Pin':
                requestTranslated = 'NEW_REQUEST.CLAVE_PIN_WITH_WIDGET';
                break;
            case 'Clave_Pin_SMS':
                requestTranslated = 'NEW_REQUEST.CLAVE_PIN';
                break;
            case 'SMS':
                requestTranslated = 'NEW_REQUEST.SMS';
                break;
            case 'Video_Id':
                requestTranslated = 'NEW_REQUEST.VIDEO_ID';
                break;
            case 'Know_Client':
                requestTranslated = 'NEW_REQUEST.KNOWN_CLIENT';
                break;
        }
        if (requestorTranslated != '' && requestTranslated != ''){
            return `${this.translateService.instant(requestorTranslated)} - ${this.translateService.instant(requestTranslated)}`;
        } else {
            return '';
        }
    }

    resetSidebar() {
        this.requestorType = '';
        this.requestType = '';
        this.myForm = this.fb.group({});
    }

    // #region NIF / NIE VALIDATION
    nifNieValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) return null; // if the field is empty, don't validate

            const valid = this.validateNifNie(value);
            return valid ? null : { nifNieValidator: true };
        };
    }

    validateNifNie(value: string): boolean {
        value = value.toUpperCase();

        const niePrefix = {
            X: 0,
            Y: 1,
            Z: 2
        };

        if (/^[0-9]{8}[A-Z]$/.test(value)) {  // NIF VALIDATION
            return this.validateNif(value);
        } else if (/^[XYZ][0-9]{7}[A-Z]$/.test(value)) {  // NIE VALIDATION
            const nie = value.replace(/[XYZ]/, (letter) => niePrefix[letter].toString());
            return this.validateNif(nie);
        } else {
            return false;
        }
    }

    private validateNif(value: string): boolean {
        const letter = value.charAt(8);
        const numbers = parseInt(value.slice(0, 8), 10);
        const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
        return letter === validLetters.charAt(numbers % 23);
    }
    // #endregion
}
