import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/User';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    currentUserSubject: BehaviorSubject<User>;
    currentUser: Observable<User>;

    private pswd: string;

    constructor() {
        const currentUser = sessionStorage.getItem('currentUser');
        const user = JSON.parse(currentUser!); // ! esto quita el null
        this.currentUserSubject = new BehaviorSubject<User>(user);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    setPswd(pswd: string) {
        this.pswd = pswd;
    }

    getPswd() {
        return this.pswd;
    }
}
