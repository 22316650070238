import { Component } from '@angular/core';
import { RequestDetailService } from '../request-detail.service';
import { Router } from '@angular/router';
import { RequestService } from '../../my-requests/request.service';
import { RequestsFilterService } from '../../my-requests/requests-filter.service';

@Component({
    selector: 'app-request-detail-header',
    templateUrl: './request-detail-header.component.html'
})
export class RequestDetailHeaderComponent {
    // Lee personQueryDetail del *servicio* y lo pinta en una tabla.
    constructor(
        public requestDetailService: RequestDetailService,
        public requestsFilterService: RequestsFilterService,
        public requestService: RequestService,
        private router: Router
    ) { }

    navigateToMyRequests() {
        this.router.navigate(['/requests']).catch(error => { console.error(error); });
    }

    isTemporaryFinishedStatus(){
        return this.requestDetailService.feasibilityData.isCreditOperationMade
            && this.requestDetailService.request.personQuery.status === 'Success';
    }
}
