import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FeasibilityRule } from 'src/app/models/FeasibilityRule';
import { RowsPerPageDefault, RowsPerPageOptions } from 'src/app/shared/constants/common.constants';

@Injectable({
    providedIn: 'root'
})
export class AdminFeasibilityService {

    feasibilityRules: FeasibilityRule[] = [];
    selectedRule: FeasibilityRule | undefined;
    selectedRuleIndex: number | undefined;
    isLoading = false;
    isSidebarVisible = false;
    lastSyncDate: Date;
    rowsPerPage = RowsPerPageDefault;
    rowsPerPageOptions = RowsPerPageOptions;

    constructor(
		private apiService: ApiService,
		private toastService: ToastService
    ) { }

    async fetchFeasibilityRules(showFetchCompletedToast = false) {
        try {
            this.isLoading = true;
            this.lastSyncDate = new Date();
            this.feasibilityRules = await this.apiService.get<FeasibilityRule[]>('rules');
            if (showFetchCompletedToast) {
                this.toastService.showSuccess('TOAST.SUCCESS', 'ADMINISTRATION.FEASIBILITY.RULES_SYNC', true);
            }
        } catch {
            this.toastService.showError('TOAST.ERROR', 'ADMINISTRATION.FEASIBILITY.ERROR_FETCHING_RULES', true);
        } finally {
            this.isLoading = false;
        }
    }

    async createFeasibilityRule(feasibilityRule: FeasibilityRule) {
        try {
            this.isLoading = true;
            this.feasibilityRules.push(feasibilityRule);
            await this.apiService.post<FeasibilityRule[]>('rules/update', this.feasibilityRules);
            await this.fetchFeasibilityRules();
            this.isSidebarVisible = false;
        } catch {
            this.toastService.showError('TOAST.ERROR', 'ADMINISTRATION.FEASIBILITY.ERROR_CREATING_RULE', true);
        } finally {
            this.isLoading = false;
        }
    }

    async updateFeasibilityRules(feasibilityRule: FeasibilityRule) {
        if (this.selectedRuleIndex === undefined) {
            return;
        }
        try {
            this.isLoading = true;
            this.feasibilityRules[this.selectedRuleIndex] = feasibilityRule;
            await this.apiService.post<FeasibilityRule[]>('rules/update', this.feasibilityRules);
            await this.fetchFeasibilityRules();
            this.isSidebarVisible = false;
        } catch {
            this.toastService.showError('TOAST.ERROR', 'ADMINISTRATION.FEASIBILITY.ERROR_UPDATING_RULE', true);
        } finally {
            this.isLoading = false;
        }
    }

    async deleteFeasibilityRule(rulePosition: number) {
        try {
            this.isLoading = true;
            this.feasibilityRules.splice(rulePosition, 1);
            await this.apiService.post<FeasibilityRule[]>('rules/update', this.feasibilityRules);
            await this.fetchFeasibilityRules();
        } catch {
            this.toastService.showError('TOAST.ERROR', 'ADMINISTRATION.FEASIBILITY.ERROR_DELETING_RULE', true);
        } finally {
            this.isLoading = false;
        }
    }
}
