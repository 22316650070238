import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { UsualModule } from 'src/app/usual.module';
import { HeaderModule } from '../header/header.module';
import { LoginModule } from '../login/login.module';



@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        CommonModule,
        UsualModule,
        HeaderModule,
        LoginModule
    ],
    exports: [
        HomeComponent
    ]
})
export class HomeModule { }
