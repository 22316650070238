import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { FilterResponse } from 'src/app/models/FilterResponse';
import { StatusConfig } from 'src/app/models/StatusConfig';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    statusConfig: StatusConfig;

    personTypes = [
        { title: 'FILTER.PERSON_TYPES.FREELANCER', id: '01' },
        { title: 'FILTER.PERSON_TYPES.EMPLOYEE', id: '00' }];

    queryTypes = [
        { title: 'FILTER.QUERY_TYPES.PHYSICAL_PERSON', id: 'PhysicalPerson' }];

    constructor(private api: ApiService) {
        this.api.loadStatusConfig().subscribe((config) => this.statusConfig = config);
    }

    getPersonTypeTitle(personType: string) {
        const _personTypeId = this.personTypes.find((person) => person.id == personType);
        return _personTypeId?.title;
    }

    getQueryTypeTitle(queryType: string) {
        const _queryTypeId = this.queryTypes.find((query) => query.id == queryType);
        return _queryTypeId?.title;
    }

    getRequestStatusTitle(status: string) {
        const _statusId = this.statusConfig?.status.find((stat) => stat.id == status);
        return _statusId?.title;
    }

    // getRequestSubstatusTitle(substatus: string) {
    // 	const _substatusId = this.statusConfig?.substatus.find((sub_stat) => sub_stat.id == substatus);
    // 	return _substatusId?.title ?? substatus;
    // }

    // propertiesInitializer(constObject: { title: string, id: string }[]) {
    //     const translatedProperty$ = [];
    //     for (const property of constObject) {
    //         translatedProperty$.push(this.translateService.get(property.title)
    //             .subscribe((res: string) => property.title = res));
    //     }
    // }

    // La clase FilterResponse contiene la lista y alguna propiedad mas como el numero de peticiones.
    async getAllRequests(): Promise<FilterResponse> {
        return await this.api.post<FilterResponse>(`requests/filter-requests`, {});
    }

    getStatusIcon(status: string): string {
        switch (status) {
            case "Created":
                return "bookmark_added";
            case "Processing":
                return "exit_to_app";
            case "Downloading":
                return "downloading";
            case "Analyzing":
                return "troubleshoot";
            case "Success":
                return "task";
            case "Canceled":
                return "do_not_disturb_on";
            case "Error":
                return "error";
            default: return "icon_not_found";
        }
    }
}
