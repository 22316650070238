import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { FeasibilityRule } from 'src/app/models/FeasibilityRule';
import { RequestDetailService } from '../request-detail.service';
import { DatePipe } from '@angular/common';
import { SaveJsonRequest } from 'src/app/models/SaveJsonRequest';
import { FeasibilityJsonFileName, OperatorEnum } from 'src/app/shared/constants/common.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Injectable({
    providedIn: 'root'
})
export class RequestDetailFeasibilityService {

    feasibilityForm: FormGroup;
    isFeasible: boolean | undefined;
    ruleFeasibilityResults: FeasibilityRule[] = [];

    credit: number | undefined;
    isValidCreditValue = false;
    isRCOB003RuleValid = false;
    private readonly minCreditLimitByDefault = 3000;
    private readonly maxCreditLimitByDefault = 15000;
    minCreditLimit = this.minCreditLimitByDefault;
    maxCreditLimit = this.maxCreditLimitByDefault;

    constructor(
		private apiService: ApiService,
		private requestDetailService: RequestDetailService,
		private datePipe: DatePipe,
		private toastService: ToastService
    ) {
        this.prepareFeasibilityForm();
    }

    private prepareFeasibilityForm() {
        this.feasibilityForm = new FormGroup({
            IngresosElegibles: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            GastosComprometidos: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            ScoreOpenBanking: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            CodigoReglaOB1: new FormControl(null, []),
            CodigoReglaOB2: new FormControl(null, []),
            ImporteDeudaTotalCirculanteCIRBE: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            ImporteMoraCIRBE: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // DescubiertosCuenta: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // IngresosRecurrentesTrimestrales: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // NumeroRecobrosUltimoAno: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // ImporteMedioGastosApuestas: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // CantidadMesesGastosApuestas: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // VariacionCaidaIngresos: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // NumeroPagosPorFinancierasMensualmente: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // SaldoMedioMinimoMensual6Meses: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
            // LimiteCreditoAsignar: new FormControl(null, [Validators.required, this.positiveNumberValidator()]),
        });
    }

    resetFeasibility() {
        this.feasibilityForm.reset();
        this.isFeasible = undefined;
        this.ruleFeasibilityResults = [];
        this.resetCreditAndLimits();
    }

    getRules(): Observable<FeasibilityRule[]> {
        const promise = this.apiService.get<FeasibilityRule[]>('rules');
        return from(promise);
    }

    validateRule(rule: FeasibilityRule, request: any) {
        switch (rule.operator) {
            case OperatorEnum.Equals:
                return this.validateEquals(request.value, rule.value);
            case OperatorEnum.NotEquals:
                return !this.validateEquals(request.value, rule.value);
            case OperatorEnum.LessThan:
                return this.validateLessThan(request.value, rule.value);
            case OperatorEnum.LessThanOrEqual:
                return this.validateLessOrEqualThan(request.value, rule.value);
            case OperatorEnum.GreaterThan:
                return this.validateGreaterThan(request.value, rule.value);
            case OperatorEnum.GreaterThanOrEqual:
                return this.validateGreaterOrEqualThan(request.value, rule.value);
            case OperatorEnum.Includes:
                return request.value.includes(rule.value);
            case OperatorEnum.DoesNotInclude:
                return !request.value.includes(rule.value);
            case OperatorEnum.StartsWith:
                if (Array.isArray(rule.value)) {
                    return rule.value.some(val => request.value.startsWith(val));
                }
                return request.value.startsWith(rule.value);
            case OperatorEnum.DoesNotStartWith:
                if (Array.isArray(rule.value)) {
                    return !rule.value.some(val => request.value.startsWith(val));
                }
                return !request.value.startsWith(rule.value);
            case OperatorEnum.EndsWith:
                return request.value.endsWith(rule.value);
            case OperatorEnum.DoesNotEndWith:
                return !request.value.endsWith(rule.value);
            case OperatorEnum.Field1PercentageGteField2:
                return (request.value * Number(rule.value) / 100) >= request.value2;
            case OperatorEnum.Field1SumField2GtParameter:
                return (Number(request.value) + Number(request.value2)) > Number(rule.value);
            case OperatorEnum.Field1MinusField2GtParameter:
                return (Number(request.value) - Number(request.value2)) > Number(rule.value);
            case OperatorEnum.Field1GteField2Percentage:
                return request.value >= (request.value2 * Number(rule.value) / 100);
            default:
                console.log('bad operator'); // TODO: delete this when the feasibility rules are well defined
                return false;
        }
    }

    // #region Credit
    calculateCreditAndLimits(income: number) {
        this.minCreditLimit = this.minCreditLimitByDefault;
        this.maxCreditLimit = this.maxCreditLimitByDefault;
        this.credit = Math.floor((income / 12) * 3 * 100) / 100; // only two decimals

        if (this.credit < this.minCreditLimitByDefault) {
            this.isValidCreditValue = false;
            this.isRCOB003RuleValid = false;
            return;
        }
        if (this.credit < this.maxCreditLimitByDefault) {
            this.maxCreditLimit = this.credit;
        } else {
            this.credit = this.maxCreditLimitByDefault;
        }
        this.calculateRCOB003Rule();
    }

    private resetCreditAndLimits() {
        this.credit = undefined;
        this.isValidCreditValue = false;
        this.isRCOB003RuleValid = false;
        this.minCreditLimit = this.minCreditLimitByDefault;
        this.maxCreditLimit = this.maxCreditLimitByDefault;
    }

    isCreditBetweenLimits() {
        if (this.credit === undefined) {
            return false;
        }
        return this.credit >= this.minCreditLimit && this.credit <= this.maxCreditLimit;
    }

    // the RCOB003 rule is valid if the income is greater than or equal to 30% of the credit
    private calculateRCOB003Rule() {
        // if (this.feasibilityForm.get('IngresosRecurrentesTrimestrales').value >= this.credit * 0.3) {
        // 	this.isValidCreditValue = true;
        // 	this.isRCOB003RuleValid = true;
        // } else {
        // 	this.isValidCreditValue = false;
        // 	this.isRCOB003RuleValid = false;
        // }

        // THIS RULE IS DEACTIVATED SO ALWAYS IS TRUE
        this.isValidCreditValue = true;
        this.isRCOB003RuleValid = true;
    }

    private async handleCreditOperation(isGranted: boolean) {
        this.requestDetailService.isLoading = true;
        const now = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
        const formValues = this.feasibilityForm.value;
        const request: SaveJsonRequest = {
            queryId: this.requestDetailService.queryId,
            data: {
                IngresosElegibles: formValues.IngresosElegibles,
                GastosComprometidos: formValues.GastosComprometidos,
                ScoreOpenBanking: formValues.ScoreOpenBanking,
                CodigoReglaOB1: formValues.CodigoReglaOB1,
                CodigoReglaOB2: formValues.CodigoReglaOB2,
                ImporteDeudaTotalCirculanteCIRBE: formValues.ImporteDeudaTotalCirculanteCIRBE,
                ImporteMoraCIRBE: formValues.ImporteMoraCIRBE,
                // DescubiertosCuenta: formValues.DescubiertosCuenta,
                // IngresosRecurrentesTrimestrales: formValues.IngresosRecurrentesTrimestrales,
                // NumeroRecobrosUltimoAno: formValues.NumeroRecobrosUltimoAno,
                // ImporteMedioGastosApuestas: formValues.ImporteMedioGastosApuestas,
                // CantidadMesesGastosApuestas: formValues.CantidadMesesGastosApuestas,
                // VariacionCaidaIngresos: formValues.VariacionCaidaIngresos,
                // NumeroPagosPorFinancierasMensualmente: formValues.NumeroPagosPorFinancierasMensualmente,
                // SaldoMedioMinimoMensual6Meses: formValues.SaldoMedioMinimoMensual6Meses,
                // LimiteCreditoAsignar: formValues.LimiteCreditoAsignar,
                "Viable": isGranted,
                "Fecha": now.toString(),
                "CalculoDelLimite": this.credit
            },
            filename: FeasibilityJsonFileName
        };
        try {
            this.requestDetailService.isCalculateViabilitySidebarVisible = false;
            await this.apiService.post<SaveJsonRequest>('requests/save-json', request);
            await this.requestDetailService.refreshRequest();
            this.toastService.showSuccess('TOAST.SUCCESS', 'REQUEST_DETAIL.OPERATION_SUCCESS', true);
        } catch (error) {
            this.toastService.showError('TOAST.ERROR', 'REQUEST_DETAIL.OPERATION_ERROR', true);
            console.error('Error saving feasibility:', error);
        } finally {
            this.requestDetailService.isLoading = false;
        }
    }

    async denyCredit() {
        await this.handleCreditOperation(false);
    }

    async grantCredit() {
        await this.handleCreditOperation(true);
    }
    // #endregion

    // #region Validators
    private validateEquals(requestValue: any, ruleValue: any): boolean {
        // strings or arrays
        if (typeof requestValue === 'string' && typeof ruleValue === 'string') {
            if (ruleValue.includes('::')) {
                const ruleValuesArray = ruleValue.split('::');
                return ruleValuesArray.includes(requestValue);
            } else {
                return requestValue === ruleValue;
            }
        }

        // numbers
        const requestValueNumber = Number(requestValue);
        const ruleValueNumber = Number(ruleValue);
        if (!isNaN(requestValueNumber) || !isNaN(ruleValueNumber)) {
            return requestValueNumber === ruleValueNumber;
        }

        // dates
        const requestDate = new Date(requestValue);
        const ruleDate = new Date(ruleValue);
        if (!isNaN(requestDate.getTime()) && !isNaN(ruleDate.getTime())) {
            return requestDate === ruleDate;
        }

        // any other type
        return false;
    }

    private validateLessThan(requestValue: any, ruleValue: any): boolean {
        // numbers
        const requestValueNumber = Number(requestValue);
        const ruleValueNumber = Number(ruleValue);
        if (!isNaN(requestValueNumber) || !isNaN(ruleValueNumber)) {
            return requestValueNumber < ruleValueNumber;
        }

        // dates
        const requestDate = new Date(requestValue);
        const ruleDate = new Date(ruleValue);
        if (!isNaN(requestDate.getTime()) && !isNaN(ruleDate.getTime())) {
            return requestDate < ruleDate;
        }

        // any other type
        return false;
    }

    private validateLessOrEqualThan(requestValue: any, ruleValue: any): boolean {
        // numbers
        const requestValueNumber = Number(requestValue);
        const ruleValueNumber = Number(ruleValue);
        if (!isNaN(requestValueNumber) || !isNaN(ruleValueNumber)) {
            return requestValueNumber <= ruleValueNumber;
        }

        // dates
        const requestDate = new Date(requestValue);
        const ruleDate = new Date(ruleValue);
        if (!isNaN(requestDate.getTime()) && !isNaN(ruleDate.getTime())) {
            return requestDate <= ruleDate;
        }

        // any other type
        return false;
    }

    private validateGreaterThan(requestValue: any, ruleValue: any): boolean {
        // numbers
        const requestValueNumber = Number(requestValue);
        const ruleValueNumber = Number(ruleValue);
        if (!isNaN(requestValueNumber) || !isNaN(ruleValueNumber)) {
            return requestValueNumber > ruleValueNumber;
        }

        // dates
        const requestDate = new Date(requestValue);
        const ruleDate = new Date(ruleValue);
        if (!isNaN(requestDate.getTime()) && !isNaN(ruleDate.getTime())) {
            return requestDate > ruleDate;
        }

        // any other type
        return false;
    }

    private validateGreaterOrEqualThan(requestValue: any, ruleValue: any): boolean {
        // numbers
        const requestValueNumber = Number(requestValue);
        const ruleValueNumber = Number(ruleValue);
        if (!isNaN(requestValueNumber) || !isNaN(ruleValueNumber)) {
            return requestValueNumber >= ruleValueNumber;
        }

        // dates
        const requestDate = new Date(requestValue);
        const ruleDate = new Date(ruleValue);
        if (!isNaN(requestDate.getTime()) && !isNaN(ruleDate.getTime())) {
            return requestDate >= ruleDate;
        }

        // any other type
        return false;
    }

    private positiveNumberValidator(): ValidatorFn {
        return (control: AbstractControl): Record<string, boolean> | null => {
            const value = control.value;
            if (value === null || value === undefined || value === '') {
                return null;
            }

            const numberValue = Number(value);
            if (isNaN(numberValue) || numberValue < 0) {
                return { 'positiveNumber': true };
            }
            return null;
        };
    }
    // #endregion
}
