import { Directive, HostListener } from '@angular/core';
import { ColumnFilter } from 'primeng/table';

@Directive({
    // global directive
    selector: 'p-columnFilter'
})
export class ColumnFilterDirective {
    //Añadimos esto a la directiva para detener el ordenamiento cuando hacemos click en el icono de filtro
    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        event.stopPropagation(); // Detiene la propagación del evento click
    }

    constructor(private columnFilter: ColumnFilter ) {
        this.columnFilter.matchMode = 'contains';
        this.columnFilter.display = 'menu';
        this.columnFilter.showMatchModes = false;
        this.columnFilter.showOperator = false;
        this.columnFilter.showAddButton = false;
        this.columnFilter.showMenu = true;
        this.columnFilter.showApplyButton = false;
        this.columnFilter.showClearButton = false;
    }
}
